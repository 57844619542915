'use strict'

const pointers = {
    components: {
        PROPERTY_TYPES: {
            /** @type {'components'} */
            COMPONENTS: 'components',
            /** @type {'componentType'} */
            COMPONENT_TYPE: 'componentType',
            /** @type {'connectionQuery'} */
            CONNECTION_QUERY: 'connectionQuery',
            /** @type {'dataQuery'} */
            DATA_QUERY: 'dataQuery',
            /** @type {'propertyQuery'} */
            PROPERTY_QUERY: 'propertyQuery',
            /** @type {'designQuery'} */
            DESIGN_QUERY: 'designQuery',
            /** @type {'behaviorQuery'} */
            BEHAVIOR_QUERY: 'behaviorQuery',
            /** @type {'styleQuery'} */
            STYLE_QUERY: 'styleQuery',
            /** @type {'layoutQuery'} */
            LAYOUT_QUERY: 'layoutQuery',
            /** @type {'breakpointsQuery'} */
            BREAKPOINTS_QUERY: 'breakpointsQuery',
            /** @type {'id'} */
            ID: 'id',
            /** @type {'layout'} */
            LAYOUT: 'layout',
            /** @type {'metaData'} */
            META_DATA: 'metaData',
            /** @type {'mobileHintsQuery'} */
            MOBILE_HINTS_QUERY: 'mobileHintsQuery',
            /** @type {'modes'} */
            MODES: 'modes',
            /** @type {'skin'} */
            SKIN: 'skin',
            /** @type {'styleId'} */
            STYLE_ID: 'styleId',
            /** @type {'parent'} */
            PARENT: 'parent',
            /** @type {'type'} */
            TYPE: 'type'
        }
    },
    data: {
        DATA_MAPS: {
            /** @type {'behaviors_data'} */
            BEHAVIORS: 'behaviors_data',
            /** @type {'connections_data'} */
            CONNECTIONS: 'connections_data',
            /** @type {'document_data'} */
            DATA: 'document_data',
            /** @type {'design_data'} */
            DESIGN: 'design_data',
            /** @type {'mobile_hints'} */
            MOBILE_HINTS: 'mobile_hints',
            /** @type {'component_properties'} */
            PROPERTIES: 'component_properties',
            /** @type {'breakpoints_data'} */
            BREAKPOINTS: 'breakpoints_data',
            /** @type {'layout_data'} */
            LAYOUT: 'layout_data',
            /** @type {'theme_data'} */
            STYLE: 'theme_data'
        }
    }
}

pointers.QUERY_TO_MAP_NAME = {
    [pointers.components.PROPERTY_TYPES.BEHAVIOR_QUERY]: pointers.data.DATA_MAPS.BEHAVIORS,
    [pointers.components.PROPERTY_TYPES.CONNECTION_QUERY]: pointers.data.DATA_MAPS.CONNECTIONS,
    [pointers.components.PROPERTY_TYPES.DATA_QUERY]: pointers.data.DATA_MAPS.DATA,
    [pointers.components.PROPERTY_TYPES.DESIGN_QUERY]: pointers.data.DATA_MAPS.DESIGN,
    [pointers.components.PROPERTY_TYPES.PROPERTY_QUERY]: pointers.data.DATA_MAPS.PROPERTIES,
    [pointers.components.PROPERTY_TYPES.STYLE_QUERY]: pointers.data.DATA_MAPS.STYLE,
    [pointers.components.PROPERTY_TYPES.STYLE_ID]: pointers.data.DATA_MAPS.STYLE,
    [pointers.components.PROPERTY_TYPES.LAYOUT_QUERY]: pointers.data.DATA_MAPS.LAYOUT,
    [pointers.components.PROPERTY_TYPES.BREAKPOINTS_QUERY]: pointers.data.DATA_MAPS.BREAKPOINTS
}

const EVENTS = {
    COMP_DATA_RUNTIME_CHANGE: 'runtimeCompChange',
    REGISTER_COMPONENT: 'registerComponentEvent',
    FONT_LOAD: 'fontLoad',
    WINDOW_RESIZE: 'windowResize'
}

const ASPECT_COMPS_CONTAINER_ID = 'aspectCompsContainer'

const REPEATER_COMP_TYPE = 'wysiwyg.viewer.components.Repeater'

const MAX_RUNTIME_OVERRIDES_ITEMS = 20

module.exports = {
    ASPECT_COMPS_CONTAINER_ID,
    MAX_RUNTIME_OVERRIDES_ITEMS,
    EVENTS,
    pointers,
    REPEATER_COMP_TYPE
}

